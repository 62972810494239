import * as React from "react";
import PageLayout from "../components/PageLayout";
import { StaticImage } from "gatsby-plugin-image";

// markup
const MysticWizards = () => {
  return (
    <PageLayout>
      <div className="center">
        <div className="content">
          <h1 className="title">Mystic Wizards</h1>
          <div className="image">
            <StaticImage src="../images/mystic-wizards.png" alt="Mystic Wizards" layout="constrained" width={400} />
          </div>
          <div className="description">
            <p>They've travelled great distances, across strange and wonderful lands. They've defeated foes, cast spells, and played weird little songs on their lutes. Join them as they unite powers and convene to form a powerful mystic circle.</p>
            <p className="cta">Use the widget below to mint a Mystic Wizard NFT</p>
          </div>
        </div>
      </div>
      <hashku-mint api-url="https://api.hashku.com/api" team-slug="mystic-wizards" project-slug="main" contract-network="1" contract-address="0xbE6A2F5960B27c7fde12E073d61b962D9C6c3cB7" infura-id="3ed982906a2d473f8918f0730b2ea194"></hashku-mint>
    </PageLayout>
  );
};

export default MysticWizards;
